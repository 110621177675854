<template>
  <div class="w-full max-h-screen bg-background h-full">
    <page-header backTo="prospect-index" title="Edit Contact - Sales">
      <button
        @click="changeViewMode"
        type="button"
        class="flex items-center bg-teal-200 border hover:bg-teal-300 border-teal-400 hover:border-teal-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <rect width="22" height="14" x="1" y="5" rx="7" ry="7" />
          <circle cx="8" cy="12" r="3" />
        </svg>
        <span>Accounts view</span>
      </button>
    </page-header>
    <main class="p-6 bg-background flex-1 overflow-y-scroll"></main>
  </div>
</template>

<script>
const PageHeader = () => import("@/components/PageHeader.vue");

export default {
  name: "SalesView",
  components: {
    PageHeader,
  },
  props: {
    contact: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      currentTab: "info",
      error: null,
    };
  },
  methods: {
    changeViewMode: function() {
      this.$emit("changeViewMode");
    },
  },
};
</script>
